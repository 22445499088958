import React from 'react'
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';

const GoogleMap = (props) => (
    <Map
        style={{
            position: 'relative',
            width: '100%',
            height: '100%'
        }}
        containerStyle={{
            position: 'relative',
            width: '100%',
            height: '500px'
        }}
        google={props.google}
        initialCenter={{
            lat: 50.161268282478005,
            lng: 19.46353419310636
        }}
        zoom={17}
        disableDoubleClickZoom
        scrollwheel={false}
        draggable={false}
        fullscreenControl={false}
        zoomControl={false}
        streetViewControl={false}
        mapTypeControl={false}
    >
        <Marker
            name={'Auto Serwis Olaszewski'}
            title={'Auto Serwis Olaszewski'}
            position={{
                lat: 50.161268282478005,
                lng: 19.46353419310636
            }}/>
    </Map>
)

export default GoogleApiWrapper({
    apiKey: (process.env.GOOGLE_MAP_API_KEY)
})(GoogleMap)
