import React from "react";

const PageTitle = ({children}) => {
    return <div className="container">
        <div className="page-title">
            <h3 className="title is-2">{children}</h3>
            <hr/>
        </div>
    </div>
};

export default PageTitle;
