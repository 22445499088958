import React from "react";
import {Helmet} from "react-helmet-async"

const SchemaOrg = () => {
    const script = [
        {
            type: 'application/ld+json',
            innerHTML:
                `{
                  "@context" : "http://schema.org",
                  "@type" : "LocalBusiness",
                  "url": "https://serwis-olaszewski.pl",
                  "name" : "Auto Serwis Olaszewski",
                  "image" : "https://serwis-olaszewski.pl/logo.png",
                  "sameAs": [
                    "https://www.facebook.com/auto.serwis.olaszewski"
                  ],
                  "telephone" : "+48 698 102 313",
                  "email" : "biuro@serwis-olaszewski.pl",
                  "address" : {
                    "@type" : "PostalAddress",
                    "streetAddress" : "ul. Św. Stanisława 46 B",
                    "addressLocality" : "Trzebinia",
                    "postalCode" : "32-540"
                    "addressCountry" : "PL"
                  },
                  "openingHours":["Mo-Fr 08:00-16:00"],
                  "openingHoursSpecification" : {
                    "@type" : "OpeningHoursSpecification",
                    "dayOfWeek" : {
                      "@type" : "DayOfWeek",
                      "name" : "Poniedziałek - Piątek"
                    },
                    "opens" : "08:00:00",
                    "closes" : "16:00:00"
                  },
                  "priceRange": "$",
                  "currenciesAccepted": "PLN",
                  "paymentAccepted":"Cash, Credit Card",
                  "contactPoint": {
                    "email": "biuro@serwis-olaszewski.pl",
                    "telephone": "+48 698 102 313",
                    "productSupported": "cars"
                  }
                }`
        }
    ];

    return <Helmet
        script={script}
    />
};

export default SchemaOrg;
