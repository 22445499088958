import React from "react";
import PropTypes from "prop-types";

import "./Question.scss";

const Question = ({title, children}) => {
    return <section className="hero is-light question">
        <div className="hero-body">
            <div className="container has-text-centered">
                <h1 className="title has-text-dark">{title}</h1>
                <h2 className="subtitle has-text-dark">{children}</h2>
            </div>
        </div>
    </section>
};

Question.propTypes = {
    title: PropTypes.string.isRequired,
}

export default Question;
