import React from "react";

import Footer from "../components/Layout/Footer/Footer";
import Layout from "../components/Layout/layout";
import Menu from "../components/Layout/Menu/Menu";
import GoogleMap from "../components/GoogleMap/GoogleMap";
import PageTitle from "../components/Layout/Page/PageTitle";
import Question from "../components/Layout/Page/Question";
import {EMAIL, PHONE_NUMBER} from "../utils/consts";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import {Link} from "gatsby";
import SchemaOrg from "../components/SEO/SchemaOrg";

export default function AboutPage() {
    return <Layout>
        <GatsbySeo
            title='Kontakt'
            description='W celu zarezerwowania terminu prosimy o kontakt telefoniczy lub e-mailowy.'
            canonical='https://serwis-olaszewski.pl/kontakt'
            openGraph={{
                title: 'Kontakt | Auto Serwis Olaszewski',
                url: 'https://serwis-olaszewski.pl/kontakt',
                description: 'W celu zarezerwowania terminu prosimy o kontakt telefoniczy lub e-mailowy.'
            }}
            twitter={{
                title: 'Kontakt | Auto Serwis Olaszewski',
                description: 'W celu zarezerwowania terminu prosimy o kontakt telefoniczy lub e-mailowy.'
            }}
        />

        <SchemaOrg/>

        <Menu/>

        <main className="has-margin-top">
            <PageTitle>Kontakt</PageTitle>

            <div className="container">
                <section className="pb-4">
                    <div className="columns">
                        <div className="column">
                            <h4 className="title is-4">Adres</h4>
                            <address>
                                <strong>Auto Serwis Olaszewski</strong><br/>
                                ul. Św. Stanisława 46 B<br/>
                                32-540 Trzebinia, PL
                            </address>

                            <p className="is-size-7">(obok kąpieliska "Balaton")</p>
                        </div>
                        <div className="column">
                            <h4 className="title is-4">Skontaktuj się z nami</h4>
                            <p className="mb-1">
                                Telefon: <a href={"tel:" + PHONE_NUMBER} className="has-text-dark">{PHONE_NUMBER}</a>
                            </p>
                            <p className="mb-4">
                                E-mail:&nbsp;
                                <a href={"mailto:" + EMAIL} className="has-text-dark">{EMAIL}</a>
                            </p>
                        </div>
                        <div className="column">
                            <h4 className="title is-4">Pracujemy w godzinach</h4>

                            <p>Poniedziałek - Piątek 8:00 - 16:00</p>
                        </div>
                    </div>
                </section>
            </div>

            <GoogleMap/>

            <Question title="Szukasz solidnego mechanika?">
                Sprawdź naszą <Link to="/zakres-uslug" className="has-text-dark">oferę</Link> i umów się na wizytę!
            </Question>
        </main>

        <Footer/>
    </Layout>
}
